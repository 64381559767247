
.safety a {
  color: white;
  text-decoration: none;
}

.nav_bax a {
  display: inline-block;
  width: 108%;
  text-decoration: none;
  padding-left: 30px;
  height: 40px;
  line-height: 40px;
  color: white;
}

.nav_bax a:hover {
  background: url(../img/end-left_02.png) no-repeat !important;
  background-size: 100% 90% !important;
}

.state a {
  text-decoration: none;
  width: 100% !important;
  height: 143px
}

.demo {
  overflow: hidden;
  width: 100%;
  height: 100%;

  background: white;
  position: relative;
  text-align: center
}

.bbody {
  color: #000;
  overflow: hidden;
  padding-bottom: 20px;
}

.bbody h2 {
  margin: 10px 0;
}

.step2 {
  height: 270px;
  width: 70%;
  border: 1px solid #eaebee;
}

.box input[type=file] {
  padding: 1px 2px;
  width: 80px;
  margin-bottom: 10px;
}

.box input[type=submit] {
  background: #e3e3e3;
  border: 1px solid #bbb;
  border-radius: 3px;
  box-shadow: inset 0 0 1px 1px #f6f6f6;
  color: #333;
  font: bold 12px/1 "helvetica neue", helvetica, arial, sans-serif;
  padding: 8px 0 9px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  width: 100px;
}

.box input[type=submit]:hover {
  background: #d9d9d9;
  box-shadow: inset 0 0 1px 1px #eaeaea;
  color: #222;

  cursor: pointer;
}

.box input[type=submit]:active {
  background: #d0d0d0;
  box-shadow: inset 0 0 1px 1px #e3e3e3;
  color: #000;
}

.box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 50px;
  background: rgba(0, 0, 0, .2);
  text-align: center;
  display: block;
  z-index: 1000;
}

.mypresent {
  width: 20em;
  float: left;
  border-left: 20px solid white
}

.modal-header {
  overflow: hidden;
  text-align: center
}

.bbody span {
  color: #CCCCCC;
  font-size: 0.9em;
  margin: 0
}

.mypresent > .present {
  margin: 100px auto;
  width: 100px;
  height: 100px;
  border: 1px solid #eaebee;
  box-shadow: 0 0 2px 2px #eaebee inset;
}

.present img {
  width: 94%;
  height: 94%;
  margin: 3px 2px;
}

.mypresent, .bbody {
  width: 48%;
  margin-left: 1%;
}

.demo p {
  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
  line-height: 50px;
  font-size: 1.5rem;
}

.demo div {
  margin: auto;
}

.step2 img {
  height: 100%;
  width: 100%
}

.sub {
  text-align: center;
}

.file {
  width: 80px;
  height: 100%;
  margin: auto
}

.main2 {
  height: 82%
}

.Capacity_box {
  width: 100%;
  height: 620px;
  border-radius: 10px;
  margin-top: 200px;
}